import { RouteRecordRaw } from "vue-router";
import { UserRoleEnum } from "@/app/core/enums/UserRoleEnum";

export const routePrivateDispatched: Array<RouteRecordRaw> = [
  {
    path: "dispatched-to-me/:status?",
    name: "DispatchedToMe",
    meta: {
      requireAuth: true,
      roles: [UserRoleEnum.Carrier, UserRoleEnum.Master],
    },
    component: () => import("@/app/private/pages/dispatched-to-me/DispatchedToMe.vue"),
  },
];
