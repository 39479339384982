import { RestService } from "@/app/core/services/core/RestService";
import { Order } from "@/app/core/models/domain/Order";
import { OrdersGetParamsContract } from "@/app/core/interfaces/services/order/OrdersGetParamsContract";
import { PaginatedResponseContract } from "@/app/core/interfaces/services/core/PaginatedResponseContract";
import { OrderChangeStatusRequestContract } from "@/app/core/interfaces/services/order/OrderChangeStatusRequestContract";
import { FileResponseContract } from "@/app/core/interfaces/services/core/FileResponseContract";
import { CRMTakeLoadRequestContract } from "@/app/core/interfaces/services/order/CRMTakeLoadRequestContract";

export class OrderService extends RestService<Order> {
  constructor() {
    super("/listings", "listings", "");
  }

  async getOrder(
    orderId: number,
    alongRoute?: boolean,
    routeRadius?: number,
    destinationRadius?: number,
    pickupRadius?: number
  ): Promise<Order> {
    return this.httpGet<Order>(`${this.apiBaseHref()}/orders/${orderId}`, {
      alongRoute,
      routeRadius,
      destinationRadius,
      pickupRadius,
    });
  }

  async getOrderPublic(
    orderId: number,
    alongRoute?: boolean,
    routeRadius?: number,
    destinationRadius?: number,
    pickupRadius?: number
  ): Promise<Order> {
    return this.httpGet<Order>(`${this.apiHref()}/public/${orderId}`, {
      alongRoute,
      routeRadius,
      destinationRadius,
      pickupRadius,
    });
  }

  async getOrderPublicValidate(
    orderId: number,
    token: string
  ): Promise<{ pickupDate: string; destinationDate: string }> {
    return this.httpGet(`${this.apiHref()}/public/validate/${orderId}/${token}`, {
      orderId,
      token,
    });
  }

  async getOrders(params: OrdersGetParamsContract): Promise<PaginatedResponseContract<Order>> {
    return this.httpGetPaginated<Order, OrdersGetParamsContract>(`${this.apiHref()}`, params);
  }

  async takeLoadCRMRequest(dto: CRMTakeLoadRequestContract): Promise<void> {
    return this.httpPost(`${this.apiHref()}/crm/request`, dto);
  }

  async changeStatus(crmId: number, dto: OrderChangeStatusRequestContract): Promise<void> {
    return this.httpPost(`${this.apiHref()}/${crmId}/status`, dto);
  }

  async downloadDispatchSheet(crmId: string): Promise<FileResponseContract> {
    return await this.httpGetBlob(`${this.apiHref()}/${crmId}/dispatch-sheet`);
  }
}
