import { RestService } from "@/app/core/services/core/RestService";
import { User } from "@/app/core/models/domain/User";
import { UserResponseContract } from "@/app/core/interfaces/services/user/UserResponseContract";
import { AddUserFormContract } from "@/app/core/interfaces/components/users/AddUserFormContract";
import { EditUserFormContract } from "@/app/core/interfaces/components/users/EditUserFormContact";
import { AdminUserRoleEnum } from "@/app/core/enums/AdminUserRoleEnum";
import { CarrierStatusType } from "@/app/core/enums/CarrierStatusType";
import { ChangeUserPasswordRequestContract } from "@/app/core/interfaces/services/user/ChangeUserPasswordRequestContract";
import { UserWithTokensResponseContract } from "@/app/core/interfaces/services/auth/UserWithTokensResponseContract";

export class UserService extends RestService<User> {
  constructor() {
    super("/users", "user", "ua");
  }

  async getCurrent(): Promise<UserResponseContract> {
    return await this.httpGet<UserResponseContract>(`${this.apiHref()}/me`);
  }

  async getUsers(
    role?: AdminUserRoleEnum,
    carrierStatus?: CarrierStatusType | null,
    page?: number,
    limit?: number
  ): Promise<any> {
    return await this.httpGetAll(`${this.apiHref()}`, {
      role,
      carrierStatus,
      page,
      limit,
    });
  }

  async uploadAvatar(file: FormData): Promise<any> {
    return await this.httpPost(`${this.apiHref()}/avatar`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async removeAvatar(): Promise<any> {
    return await this.httpDelete(`${this.apiHref()}/avatar`);
  }

  async createUser(dto: AddUserFormContract): Promise<boolean> {
    return await this.httpPost(`${this.apiHref()}/create`, dto);
  }

  async editUser(id: number, dto: EditUserFormContract): Promise<boolean> {
    return await this.httpPatch(`${this.apiHref()}/edit/${id}`, dto);
  }

  async updateCarrierStatusByAdmin(id: number, status: CarrierStatusType): Promise<boolean> {
    return await this.httpPatch(`${this.apiHref()}/edit/status/${id}`, { status });
  }

  async deleteUser(id: number): Promise<boolean> {
    return await this.httpDelete(`${this.apiHref()}/delete/${id}`);
  }

  async changePassword(dto: ChangeUserPasswordRequestContract): Promise<boolean> {
    return await this.httpPatch(`${this.apiHref()}/changePassword`, dto);
  }

  async generateUserCredentials(token: string): Promise<UserWithTokensResponseContract> {
    return await this.httpGet(`${this.apiHref()}/generate/credentials/${token}`, { token });
  }
}
