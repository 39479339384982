import { OrdersGetParamsContract } from "@/app/core/interfaces/services/order/OrdersGetParamsContract";
import { Actions, createComposable, Getters, Module, Mutations } from "vuex-smart-module";
import { Order } from "@/app/core/models/domain/Order";
import { container } from "tsyringe";
import { OrderService } from "@/app/core/services/OrderService";
import { OtherFiltersContract } from "@/app/core/interfaces/components/modals/other-filters/OtherFiltersContract";
import { VehicleType } from "@/app/core/enums/VehicleType";
import { PaymentTypeEnum } from "@/app/core/enums/PaymentTypeEnum";
import { PaginationMetaContract } from "@/app/core/interfaces/services/core/PaginationMetaContract";
import { ConditionType } from "@/app/core/enums/ConditionType";
import { SearchOrdersSort } from "@/app/core/enums/SearchOrdersSort";
import { SearchOrdersType } from "@/app/core/enums/SearchOrdersType";
import { SearchForOrdersFilterContract } from "@/app/core/interfaces/components/orders/SearchForOrdersFilterContract";
import { State } from "@/app/core/models/domain/State";
import { Area } from "@/app/core/models/domain/Area";
import { DaysTypeEnum } from "@/app/core/enums/DaysTypeEnum";

export class OrderState {
  pickupLat: number | null = null;
  pickupLong: number | null = null;
  radiusFrom: number = 50;
  destinationLat: number | null = null;
  destinationLong: number | null = null;
  radiusTo: number = 50;
  pickupStates: State[] = [];
  destinationStates: State[] = [];
  pickupRegions: Area[] = [];
  destinationRegions: Area[] = [];
  pickupCoasts: Area[] = [];
  destinationCoasts: Area[] = [];
  pickupType: SearchOrdersType = SearchOrdersType.Location;
  destinationType: SearchOrdersType = SearchOrdersType.Location;
  minPaymentTotal: number | null = null;
  readyPickupIn: DaysTypeEnum = DaysTypeEnum.TenDays;
  condition: ConditionType | null = null;
  vehTypes: VehicleType[] | null = [VehicleType.Car, VehicleType.SUV, VehicleType.Pickup];
  minVeh: number = 1;
  maxVeh: number = 20;
  postedWithin: number = 30;
  paymentTypes: PaymentTypeEnum[] = [];
  page: number = 1;
  limit: number = 50;
  pickupOrderId: string | undefined = undefined;
  destinationOrderId: string | undefined = undefined;
  sortBy: SearchOrdersSort = SearchOrdersSort.PostedDesc;
  orders: Order[] | null;
  pagination: PaginationMetaContract;
}
export class OrderGetters extends Getters<OrderState> {
  get orders(): Order[] | null {
    return this.state.orders;
  }
  get ordersCount(): number {
    return this.state.pagination ? this.state.pagination.totalItems : 0;
  }
  get filter(): SearchForOrdersFilterContract {
    return {
      pickupLat: this.state.pickupLat,
      pickupLong: this.state.pickupLong,
      radiusFrom: this.state.radiusFrom,
      destinationLat: this.state.destinationLat,
      destinationLong: this.state.destinationLong,
      radiusTo: this.state.radiusTo,
      pickupStates: this.state.pickupStates,
      destinationStates: this.state.destinationStates,
      pickupRegions: this.state.pickupRegions,
      destinationRegions: this.state.destinationRegions,
      pickupCoasts: this.state.pickupCoasts,
      destinationCoasts: this.state.destinationCoasts,
      pickupType: this.state.pickupType,
      destinationType: this.state.destinationType,
      minPaymentTotal: this.state.minPaymentTotal,
      readyPickupIn: this.state.readyPickupIn,
      condition: this.state.condition,
      vehTypes: this.state.vehTypes,
      minVeh: this.state.minVeh,
      maxVeh: this.state.maxVeh,
      postedWithin: this.state.postedWithin,
      paymentTypes: this.state.paymentTypes,
      pickupOrderId: this.state.pickupOrderId,
      destinationOrderId: this.state.destinationOrderId,
      page: this.state.page,
      limit: this.state.limit,
      sortBy: this.state.sortBy,
    };
  }
  get searchOrdersParams(): OrdersGetParamsContract {
    const pickupTypeResolver = () => {
      switch (this.state.pickupType) {
        case SearchOrdersType.Location:
          return this.state.pickupLat && this.state.pickupLong && this.state.radiusFrom
            ? this.state.pickupType
            : undefined;
        case SearchOrdersType.State:
          return this.state.pickupStates.length ? this.state.pickupType : undefined;
        case SearchOrdersType.Coast:
          return this.state.pickupCoasts.length ? this.state.pickupType : undefined;
        case SearchOrdersType.Region:
          return this.state.pickupRegions.length ? this.state.pickupType : undefined;
        case SearchOrdersType.OrderID:
          return this.state.pickupOrderId ? this.state.pickupType : undefined;
        default:
          return undefined;
      }
    };
    const destinationTypeResolver = () => {
      switch (this.state.destinationType) {
        case SearchOrdersType.Location:
          return this.state.destinationLat && this.state.destinationLong && this.state.radiusTo
            ? this.state.destinationType
            : undefined;
        case SearchOrdersType.State:
          return this.state.destinationStates.length ? this.state.destinationType : undefined;
        case SearchOrdersType.Coast:
          return this.state.destinationCoasts.length ? this.state.destinationType : undefined;
        case SearchOrdersType.Region:
          return this.state.destinationRegions.length ? this.state.destinationType : undefined;
        case SearchOrdersType.OrderID:
          return this.state.destinationOrderId ? this.state.destinationType : undefined;
        default:
          return undefined;
      }
    };
    return {
      pickupLat:
        this.state.pickupType === SearchOrdersType.Location ? this.state.pickupLat : undefined,
      pickupLong:
        this.state.pickupType === SearchOrdersType.Location
          ? this.state.pickupLong
          : undefined,
      radiusFrom:
        this.state.pickupType === SearchOrdersType.Location &&
        this.state.pickupLong &&
        this.state.pickupLat
          ? this.state.radiusFrom
          : undefined,
      destinationLat:
        this.state.destinationType === SearchOrdersType.Location
          ? this.state.destinationLat
          : undefined,
      destinationLong:
        this.state.destinationType === SearchOrdersType.Location
          ? this.state.destinationLong
          : undefined,
      radiusTo:
        this.state.destinationType === SearchOrdersType.Location &&
        this.state.destinationLong &&
        this.state.destinationLat
          ? this.state.radiusTo
          : undefined,
      pickupStates:
        this.state.pickupType === SearchOrdersType.State && this.state.pickupStates
          ? this.state.pickupStates.map((x) => x.abbreviation)
          : undefined,
      destinationStates:
        this.state.destinationType === SearchOrdersType.State && this.state.destinationStates
          ? this.state.destinationStates.map((x) => x.abbreviation)
          : undefined,
      pickupRegions:
        this.state.pickupType === SearchOrdersType.Region
          ? this.state.pickupRegions.map((x) => x.id)
          : undefined,
      destinationRegions:
        this.state.destinationType === SearchOrdersType.Region
          ? this.state.destinationRegions.map((x) => x.id)
          : undefined,
      pickupCoasts:
        this.state.pickupType === SearchOrdersType.Coast
          ? this.state.pickupCoasts.map((x) => x.id)
          : undefined,
      destinationCoasts:
        this.state.destinationType === SearchOrdersType.Coast
          ? this.state.destinationCoasts.map((x) => x.id)
          : undefined,
      pickupOrderId:
        this.state.pickupType === SearchOrdersType.OrderID
          ? this.state.pickupOrderId
          : undefined,
      destinationOrderId:
        this.state.destinationType === SearchOrdersType.OrderID
          ? this.state.destinationOrderId
          : undefined,
      pickupType: pickupTypeResolver(),
      destinationType: destinationTypeResolver(),
      minPaymentTotal: this.state.minPaymentTotal,
      readyPickupIn: this.state.readyPickupIn,
      condition: this.state.condition,
      vehTypes: this.state.vehTypes,
      minVeh: this.state.minVeh,
      maxVeh: this.state.maxVeh,
      postedWithin: this.state.postedWithin,
      paymentTypes: this.state.paymentTypes,
      page: this.state.page,
      limit: this.state.limit,
      sortBy: this.state.sortBy,
    };
  }
  get pagination(): PaginationMetaContract {
    return this.state.pagination;
  }
}
export class OrderMutations extends Mutations<OrderState> {
  setOtherFilter(payload: OtherFiltersContract) {
    this.state.paymentTypes = payload.paymentTypes;
    this.state.condition = payload.condition;
    this.state.minPaymentTotal = payload.minPayPerLoad;
    this.state.minVeh = payload.minVeh;
    this.state.maxVeh = payload.maxVeh;
    this.state.postedWithin = payload.postedWithin;
  }
  setPickupLat(lat: number | null) {
    this.state.pickupLat = lat;
  }
  setPickupLong(long: number | null) {
    this.state.pickupLong = long;
  }
  setRadiusFrom(radius: number) {
    this.state.radiusFrom = radius;
  }
  setDestinationLat(lat: number | null) {
    this.state.destinationLat = lat;
  }
  setDestinationLong(long: number | null) {
    this.state.destinationLong = long;
  }
  setRadiusTo(radius: number) {
    this.state.radiusTo = radius;
  }
  setPickupStates(states: State[]) {
    this.state.pickupStates = states;
  }
  setDestinationStates(states: State[]) {
    this.state.destinationStates = states;
  }
  setPickupCoasts(coasts: Area[]) {
    this.state.pickupCoasts = coasts;
  }
  setDestinationCoasts(coasts: Area[]) {
    this.state.destinationCoasts = coasts;
  }
  setPickupRegions(regions: Area[]) {
    this.state.pickupRegions = regions;
  }
  setDestinationRegions(regions: Area[]) {
    this.state.destinationRegions = regions;
  }
  setPickupType(type: SearchOrdersType) {
    this.state.pickupType = type;
  }
  setDestinationType(type: SearchOrdersType) {
    this.state.destinationType = type;
  }
  setMinPaymentTotal(minPaymentTotal: number | null) {
    this.state.minPaymentTotal = minPaymentTotal;
  }
  setReadyPickupIn(readyPickupIn: DaysTypeEnum) {
    this.state.readyPickupIn = readyPickupIn;
  }
  setVehTypes(vehicleTypes: VehicleType[] | null) {
    this.state.vehTypes = vehicleTypes;
  }
  setMinVeh(minVeh: number) {
    this.state.minVeh = minVeh;
  }
  setMaxVeh(maxVeh: number) {
    this.state.maxVeh = maxVeh;
  }
  setPostedWithin(postedWithin: number) {
    this.state.postedWithin = postedWithin;
  }
  setPaymentTypes(paymentTypes: PaymentTypeEnum[]) {
    this.state.paymentTypes = paymentTypes;
  }
  setPickUpOrderId(pickupOrderId: string | undefined) {
    this.state.pickupOrderId = pickupOrderId;
  }
  setDestinationOrderId(destinationOrderId: string | undefined) {
    this.state.destinationOrderId = destinationOrderId;
  }

  setCondition(condition: ConditionType | null) {
    this.state.condition = condition;
  }

  swapFromTo() {
    let tempValue: any;
    switch (this.state.pickupType) {
      case SearchOrdersType.Location:
        tempValue = {
          pickupLat: this.state.pickupLat,
          pickupLong: this.state.pickupLong,
          radiusFrom: this.state.radiusFrom,
        };
        break;
      case SearchOrdersType.Coast:
        tempValue = this.state.pickupCoasts;
        break;
      case SearchOrdersType.Region:
        tempValue = this.state.pickupRegions;
        break;
      case SearchOrdersType.State:
        tempValue = this.state.pickupStates;
        break;
      case SearchOrdersType.OrderID:
        tempValue = this.state.pickupOrderId;
        break;
      default:
        break;
    }
    switch (this.state.destinationType) {
      case SearchOrdersType.Location:
        this.state.pickupLat = this.state.destinationLat;
        this.state.pickupLong = this.state.destinationLong;
        this.state.radiusFrom = this.state.radiusTo;
        this.state.destinationLat = null;
        this.state.destinationLong = null;
        this.state.radiusTo = 50;
        break;
      case SearchOrdersType.Coast:
        this.state.pickupCoasts = this.state.destinationCoasts;
        this.state.destinationCoasts = [];
        break;
      case SearchOrdersType.Region:
        this.state.pickupRegions = this.state.destinationRegions;
        this.state.destinationRegions = [];
        break;
      case SearchOrdersType.State:
        this.state.pickupStates = this.state.destinationStates;
        this.state.destinationStates = [];
        break;
      case SearchOrdersType.OrderID:
        this.state.pickupOrderId = this.state.destinationOrderId;
        this.state.destinationOrderId = undefined;
        break;
      default:
        break;
    }
    switch (this.state.pickupType) {
      case SearchOrdersType.Location:
        this.state.destinationLat = tempValue.pickupLat;
        this.state.destinationLong = tempValue.pickupLong;
        this.state.radiusTo = tempValue.radiusFrom;
        break;
      case SearchOrdersType.Coast:
        this.state.destinationCoasts = tempValue;
        break;
      case SearchOrdersType.Region:
        this.state.destinationRegions = tempValue;
        break;
      case SearchOrdersType.State:
        this.state.destinationStates = tempValue;
        break;
      case SearchOrdersType.OrderID:
        this.state.destinationOrderId = tempValue;
        break;
      default:
        break;
    }
    if (this.state.pickupType !== this.state.destinationType) {
      const temp = this.state.pickupType;
      this.state.pickupType = this.state.destinationType;
      this.state.destinationType = temp;
    }
  }

  resetFilters() {
    this.state.radiusFrom = 50;
    this.state.radiusTo = 50;
    this.state.pickupLat = null;
    this.state.pickupLong = null;
    this.state.destinationLat = null;
    this.state.destinationLong = null;
    this.state.minVeh = 1;
    this.state.maxVeh = 20;
    this.state.condition = null;
    this.state.vehTypes = [VehicleType.Car, VehicleType.SUV, VehicleType.Pickup];
    this.state.minPaymentTotal = null;
    this.state.paymentTypes = [];
    this.state.readyPickupIn = DaysTypeEnum.TenDays;
    this.state.postedWithin = 30;
    this.state.pickupStates = [];
    this.state.destinationStates = [];
    this.state.pickupRegions = [];
    this.state.destinationRegions = [];
    this.state.pickupCoasts = [];
    this.state.destinationCoasts = [];
    this.state.pickupOrderId = undefined;
    this.state.destinationOrderId = undefined;
  }
  resetOtherFilters() {
    this.state.minPaymentTotal = null;
    this.state.paymentTypes = [];
    this.state.readyPickupIn = DaysTypeEnum.TenDays;
    this.state.postedWithin = 30;
    this.state.minVeh = 1;
    this.state.maxVeh = 20;
  }
  setCurrentPage(page: number) {
    this.state.page = page;
  }
  setPageSize(size: number) {
    this.state.limit = size;
  }
  setSortBy(sortBy: SearchOrdersSort) {
    this.state.sortBy = sortBy;
  }
  setOrders(payload: Order[]) {
    this.state.orders = payload;
  }
  setPagination(payload: PaginationMetaContract) {
    this.state.pagination = payload;
  }
  changeBookmarkedStatus(payload: { orderId: number; status: boolean }): void {
    if (this.state.orders?.find((x) => x.id === payload.orderId)) {
      this.state.orders!.find((x) => x.id === payload.orderId)!.bookmarked = payload.status;
    }
  }
}
export class OrderActions extends Actions<
  OrderState,
  OrderGetters,
  OrderMutations,
  OrderActions
> {
  async getOrders() {
    const response = await container
      .resolve(OrderService)
      .getOrders(this.getters.searchOrdersParams);
    this.mutations.setOrders(response.data);
    this.mutations.setPagination(response.meta);
  }
}
export const orderModule = new Module({
  state: OrderState,
  getters: OrderGetters,
  mutations: OrderMutations,
  actions: OrderActions,
});
export const useOrderModule = createComposable(orderModule);
