import { UserWithTokensResponseContract } from "@/app/core/interfaces/services/auth/UserWithTokensResponseContract";
import axios from "axios";
import { container } from "tsyringe";
import { EnvironmentService } from "@/app/core/services/core/EnvironmentService";

export class TokenService {
  environmentService: EnvironmentService = container.resolve(EnvironmentService);
  async refreshTokens(): Promise<UserWithTokensResponseContract> {
    const response = await axios.get<UserWithTokensResponseContract>(
      this.environmentService.apiHost
        ? `${this.environmentService.apiHost}/api/users/refresh`
        : `/api/users/refresh`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  }
}
