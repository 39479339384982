export enum UserRoleEnum {
  Shipper = "shipper",
  Carrier = "carrier",
  Admin = "admin",
  Dispatcher = "dispatcher",
  Master = "master-account",
}

export const UserRoleDisplay = [
  { name: "Shipper", value: UserRoleEnum.Shipper },
  { name: "Carrier", value: UserRoleEnum.Carrier },
  { name: "Admin", value: UserRoleEnum.Admin },
  { name: "Dispatcher", value: UserRoleEnum.Dispatcher },
  { name: "Master", value: UserRoleEnum.Master },
];
