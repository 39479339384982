import { LoginRequestContract } from "@/app/core/interfaces/services/auth/LoginRequestContract";
import { UserWithTokensResponseContract } from "@/app/core/interfaces/services/auth/UserWithTokensResponseContract";
import { RegisterUserRequestContract } from "@/app/core/interfaces/services/auth/RegisterUserRequestContract";
import { RestService } from "@/app/core/services/core/RestService";

export class AuthService extends RestService {
  constructor() {
    super("/users", "users", "");
  }

  async login(dto: LoginRequestContract): Promise<UserWithTokensResponseContract> {
    const values = {
      email: dto.username,
      password: dto.password,
    };
    return await this.httpPost<
      { email: string; password: string },
      UserWithTokensResponseContract
    >(`${this.apiHref()}/login`, values, {
      headers: {
        "Recaptcha-Token": dto.recaptchaToken!.toString(),
      },
    });
  }

  async registration(
    dto: RegisterUserRequestContract
  ): Promise<UserWithTokensResponseContract> {
    return await this.httpPost<RegisterUserRequestContract, UserWithTokensResponseContract>(
      `${this.apiHref()}/register`,
      dto
    );
  }

  async logout(): Promise<void> {
    return await this.httpPost(`${this.apiHref()}/logout`);
  }

  async forgotPassword(email: string): Promise<boolean> {
    return await this.httpPost(`${this.apiHref()}/forgotPassword`, { email });
  }

  async resetPasswordValidateToken(id: string, token: string): Promise<boolean> {
    return await this.httpGet(`${this.apiHref()}/resetPassword/validate/${id}/${token}`);
  }

  async resetPassword(password: string, id: string, token: string): Promise<boolean> {
    return await this.httpPatch(`${this.apiHref()}/resetPassword/${id}/${token}`, {
      password: password,
    });
  }
}
