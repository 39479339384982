import { RouteRecordRaw } from "vue-router";
import { UserRoleEnum } from "@/app/core/enums/UserRoleEnum";

export const routePrivateSearch: Array<RouteRecordRaw> = [
  {
    path: "search",
    name: "Search",
    meta: {
      requireAuth: true,
      roles: [UserRoleEnum.Shipper, UserRoleEnum.Carrier, UserRoleEnum.Master],
    },
    redirect: (to) => {
      return { name: "SearchListings" };
    },
    component: () => import("@/app/private/pages/search-for-orders/SearchForOrders.vue"),
    children: [
      {
        path: "",
        name: "SearchListings",
        component: () =>
          import(
            "@/app/private/pages/search-for-orders/pages/listings/SearchForOrdersListings.vue"
          ),
      },
      {
        path: "bookmarks",
        name: "SearchBookmarks",
        component: () =>
          import("@/app/private/pages/search-for-orders/pages/SearchForOrdersBookmarks.vue"),
      },
      {
        path: "saved",
        name: "SearchSaved",
        component: () =>
          import("@/app/private/pages/search-for-orders/pages/saved/SearchForOrdersSaved.vue"),
      },
      {
        path: ":id",
        name: "Order",
        props: true,
        component: () =>
          import("@/app/private/pages/search-for-orders/pages/order/SearchForOrdersOrder.vue"),
      },
    ],
  },
];
